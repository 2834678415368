<script setup lang="ts">
interface Props {
  value?: boolean | undefined;
  className?: String | string | undefined;
  [x: string]: any;
}

const props = withDefaults(defineProps<Props>(), {
});

const slots = useSlots();

const emit = defineEmits(['click', 'toggle']);
const model = defineModel<boolean>({ required: false, default: false });

if (props.value !== undefined)
  model.value = props.value;

const onToggle = function (e: Event) {
  const detailsElement = e.target as HTMLDetailsElement;
  if (detailsElement.open) {
    if (!model.value)
      model.value = true;
  }
  else
    if (model.value)
      model.value = false;
}
</script>

<template>
  <details @click="(e: Event) => (emit('click', e))" @toggle="(e: Event) => (onToggle(e), emit('toggle', e))"
    :open="model" :class="className">
    <summary v-show="slots.summary" class="cursor-pointer">
      <slot name="summary" />
    </summary>
    <slot />
  </details>
</template>

<style lang="scss" scoped>
details {
  //@apply open:animate-[down-fade-in_1s_ease-in-out]; Use class name instead!!!

  >summary {
    //list-style: none;
    @apply list-none;

    /* Latest Chrome, Edge, Firefox */
    &::marker,
    /* Safari */
    &::-webkit-details-marker {
      @apply hidden;
    }
  }
}
</style>
